import React from 'react';
import { border, philosophyContainer, philosophy, multiColumnGrid, multiColumnTileLeft, multiColumnTileRight, container, concept, titleClass, description } from './Philosophy.css'
import { sprinkles, Sprinkles } from '../../../styles/sprinkles.css';

const Philosophy = React.memo(() => {

    const spGrid = sprinkles({
        flexDirection: {
            mobile: 'column',
            tablet: 'row',
            desktop: 'row',
        },
    });

    const spTitleClass = sprinkles({
        paddingLeft: {
            mobile: '0',
            desktop: '80'
        },
    })

    return (
        <>
            <div className="inner">
                <span className={border}></span>
                <div className={philosophyContainer}>
                    <h3 className={philosophy} id="philosophy">私たちの理念</h3>
                </div>
                <div className={`${spGrid} ${multiColumnGrid}`} >
                    <div className={multiColumnTileLeft} >
                        <div className={container}>
                            <p className={`${titleClass} ${spTitleClass}`}>MISSION</p>
                        </div>
                    </div>
                    <div className={multiColumnTileRight} >
                        <p className={concept}>世界中に「かっこいい」経営者を増やす。</p>
                        <p className={description}>CRYINGTIGERは、世界中に「人を幸せにし、楽しむ心を持ち、思いやりを持って生きる」という考え方を持った経営者を増やし、より良い世界を実現します。</p>
                    </div>
                </div>
                <div className={`${spGrid} ${multiColumnGrid}`} >
                    <div className={multiColumnTileLeft} >
                        <div className={container}>
                            <p className={`${titleClass} ${spTitleClass}`}>VISION</p>
                        </div>
                    </div>
                    <div className={multiColumnTileRight} >
                        <p className={concept}>頑張ったら夢を叶えられ努力が報われる平等な世界を実現する。</p>
                        <p className={description}>世界中の「かっこいい」経営者により、頑張ったら夢を叶えられ努力が報われる平等な世界を実現することを目指します。</p>
                    </div>
                </div>
                <div className={`${spGrid} ${multiColumnGrid}`} >
                    <div className={multiColumnTileLeft} >
                        <div className={container}>
                            <p className={`${titleClass} ${spTitleClass}`}>VALUE</p>
                        </div>
                    </div>
                    <div className={multiColumnTileRight} >
                        <p className={concept}>「かっこいい」を追求する。</p>
                        <p className={description}>CRYINGTIGERでは「稼ぐ・遊ぶ・貢献する」をバランスよくこなすことを「かっこいい」と定義し、その「かっこいい」を追求します。</p>
                    </div>
                </div>
            </div>

        </>
    );
});

export default Philosophy;
