import "src/components/organisms/WordPress/Philosophy.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/Philosophy.css.tsx.vanilla.css\",\"source\":\"Ll85NmU0bGwwIHsKICBib3JkZXItYm90dG9tOiAzcHggc29saWQgI0NBMkMzMzsKICB3aWR0aDogNTBweDsKICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7Cn0KLl85NmU0bGwxIHsKICBtYXJnaW4tdG9wOiA3cmVtOwogIG1hcmdpbi1ib3R0b206IDdyZW07Cn0KLl85NmU0bGwyIHsKICBmb250LXNpemU6IDIuMnJlbTsKICBmb250LWZhbWlseTogIkJlYmFzIE5ldWUiLCBjdXJzaXZlOwogIGZvbnQtd2VpZ2h0OiA2MDA7Cn0KLl85NmU0bGwzIHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKICBmbGV4LXdyYXA6IG5vd3JhcDsKICBjb2x1bW4tZ2FwOiAwcmVtOwogIHJvdy1nYXA6IDJyZW07CiAgcGFkZGluZzogNy4ycmVtIDA7CiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICNFNUU1RTU7CiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkICNFNUU1RTU7Cn0KLl85NmU0bGwzOmxhc3QtY2hpbGQgewogIG1hcmdpbi1ib3R0b206IDE0cmVtOwp9Ci5fOTZlNGxsNCB7CiAgZmxleC1iYXNpczogMzAlOwogIHotaW5kZXg6IDE7Cn0KLl85NmU0bGw1IHsKICBmbGV4LWJhc2lzOiA3MCU7CiAgei1pbmRleDogMTsKfQouXzk2ZTRsbDYgewogIGRpc3BsYXk6IGZsZXg7CiAgZ2FwOiAzcmVtOwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47Cn0KLl85NmU0bGw3IHsKICBmb250LXNpemU6IDIuOHJlbTsKICBjb2xvcjogI0NEMkMyRTsKICBmb250LWZhbWlseTogIkJlYmFzIE5ldWUiLCBjdXJzaXZlOwogIGZvbnQtd2VpZ2h0OiA2MDA7Cn0KLl85NmU0bGw4IHsKICBmb250LXNpemU6IDIuNHJlbTsKICBsaW5lLWhlaWdodDogM3JlbTsKICBmb250LXdlaWdodDogNjAwOwp9Ci5fOTZlNGxsOSB7CiAgZm9udC1zaXplOiAxLjZyZW07CiAgbGluZS1oZWlnaHQ6IDNyZW07Cn0=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var border = '_96e4ll0';
export var concept = '_96e4ll8';
export var container = '_96e4ll6';
export var description = '_96e4ll9';
export var multiColumnGrid = '_96e4ll3';
export var multiColumnTileLeft = '_96e4ll4';
export var multiColumnTileRight = '_96e4ll5';
export var philosophy = '_96e4ll2';
export var philosophyContainer = '_96e4ll1';
export var titleClass = '_96e4ll7';