import "src/components/organisms/WordPress/CompanyInfo.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/CompanyInfo.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE5VUXY/bIBB8z69YXVWplYoV2/k68tL21Nf+B2w2CQ0GBKRJrsp/r8CO7cTOXas8oKxnZpcZINmbk0nNFP5MAAptOVpSaO91RSE3J3BaCg4fXr5lL3m+ngAcBfc7CvOpOYW/XDgj2ZmCUFIoJIXU5X49uUySWjiNwkY74YVWFFjhtDx4DNwdiu3OU0iTbF6rNeJZI16wcr+1+qA4KbXUloJFHj80E5I0aZivRCiOJwppr3kWm1fMboUiXhsKS4vVuqtddepyy8sjb6OVJ068IoUsyRpiLG5YJeSZwtN3LJiDn3jApy9QHqwTv7FFHZvtLabTnvbszhCLkvmG1pq5kRh3FVbChcWyAetjKP86OC82Z1Jq5VF5Cs6wEkmB/oioWuLRMkNB6bCGomGcC7WlkCcLixVM18PM0y7zH/Pw62GigyOAbm9UMudJuROS961vxWe3Ps9rn8OsBXPCUcinHx+muRigl2+glxE9cHQbLMmvYd7ZW2p5qFRPZHV/ENIk7x+E8YifI0sK54nzZ4nEnw2GIFR3OO7l4uXpLsTqxid2u5etFfEWhJV4rIxkHkk9fO3h1ZmRxFvRIopGjZpKnGfWRxdv66g4haytWn0cQkOxw72/xa54dSk8IcILJnszlo9mzEZnzP99xrYF/z8bRltkwxZ5rwXWV77OgkjcBPWR/V8PyIOTc5l8rZALBq60iAqY4vCpEoo0r+ZysTKnz7HXzePbPqt5+1peOkzeYHqR5e1rdxfavCn36KshPU2WQ9zzGG413iZLZgN+8Ra/h+MN7tGZeRTpg1BHYg3tLn8BMfjewjcHAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var border = 'kpxp1p0';
export var borderBottom = 'kpxp1p1';
export var container = 'kpxp1p7';
export var description = 'kpxp1p9';
export var gridLayout = 'kpxp1pa';
export var gridLeft = 'kpxp1pb';
export var gridMap = 'kpxp1pd';
export var gridText = 'kpxp1pc';
export var multiColumnGrid = 'kpxp1p4';
export var multiColumnTileLeft = 'kpxp1p5';
export var multiColumnTileRight = 'kpxp1p6';
export var philosophy = 'kpxp1p3';
export var philosophyContainer = 'kpxp1p2';
export var titleClass = 'kpxp1p8';
export var ul = 'kpxp1pe';