import "src/components/organisms/WordPress/TextImage03.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/WordPress/TextImage03.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE5VUy27bMBC8+ysWKAq0Bwp62JZMXwIk7W8UlLi2mUikQNKRk8L/XpCmFb1StPDBwHhmdnaX6+hXsj5nxmxi+L0C4MK0NXujcKjxsl8BPJ+NFYc3UilpUVoKpmUVkhJthygdwzFJp1lLQSr37cBK1edGkqNDk1hj40CtuhuSRmmAGqaPQpJSWasaCsk64N6UC42VFUrS4LdfXVfRPXDiA3tiyYwwFPL4q9O+EyE5XigkI34642d/5WeeHwJa1VKI90sT6rtKov9Iv/Hu6hX1oVYdhZPg/DbPTnB7orC7hWvYhQQkiT+gE4rjyQ6x8Rx9kEG5rS83MZqaqPIZK0sOwrrIr6gHYKuMCM2gtO6ngXt+G62SlhjxjsNR9OMSshYSSVmr6uXTxAAt41zII4W4h7xvF7Ku43hUupiX3gZdpWqlKXx5fEof0x8O8QnuXadRPp3S7vM+JtJiKmVz6WZJmk2F5Vy4+ydh5YUlq16OWp0lJ/d+f+bu44fZL42VRtVni4MHVowfwbaI28t+8uLzvomwF1LjwQ5Our8dv5WHBrlgYCqNKIFJDt8aIe/vN98W7eW7Tx2N/3XmF6NV5+yvQ24WuMOAybrf0OASswCN5JsgnxzBiJPf4yztccQs5sysDzJa3KJ8t1Rou2zw0c31DxrjjhOuBQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backgroundDiv = '_14u3ss5c';
export var container = '_14u3ss53';
export var delegate = '_14u3ss5a';
export var delegateName = '_14u3ss5b';
export var descriptionClass = '_14u3ss59';
export var image = '_14u3ss56';
export var imageContainer = '_14u3ss55';
export var multiColumnGrid = '_14u3ss50';
export var multiColumnTileBottom = '_14u3ss51';
export var multiColumnTileTop = '_14u3ss52';
export var subTitle = '_14u3ss58';
export var textContainer = '_14u3ss54';
export var titleClass = '_14u3ss57';