import React, { useContext, useEffect, useRef } from 'react';
import { multiColumnGrid, multiColumnTileBottom, multiColumnTileTop, container, textContainer, imageContainer, image, titleClass, subTitle, descriptionClass, delegate, delegateName, backgroundDiv } from './TextImage03.css'
import { sprinkles, Sprinkles } from '../../../styles/sprinkles.css';
import ViewMoreButton from './ViewMoreButton';

interface Props {
    title: string,
    content: string,
    imageSrc: string,
    imageAlt: string,
    textAlign: Sprinkles['textAlign'],
    right?: number,
    left?: number,
    flexDirection: Sprinkles['flexDirection'],
}

const TextImage02 = React.memo(({
    title,
    content,
    imageSrc,
    imageAlt,
    textAlign,
    right,
    left }: Props) => {

    return (
        <>
            <div className="inner">
                <div className={multiColumnGrid}>
                    <div className={multiColumnTileTop} >
                        <div className={imageContainer} style={{

                        }}>
                            <img className={image} src={imageSrc} alt={imageAlt} />
                        </div>
                    </div>
                    <div className={multiColumnTileBottom} id="greeting">
                        <div className={container}>
                            <h3 className={titleClass}>代表挨拶</h3>
                            <p className={subTitle}>
                                自立した経営者として、<br />
                                共に「かっこいい」を追求しよう。
                            </p>
                            <p className={descriptionClass}>
                                虎は自然界では珍しく、主に１頭で生活する肉食動物です。１頭で狩りをし、縄張りを守る、個として自立した存在です。それゆえ、孤高の存在として古くから神格化されてきました。<br />
                                <br />
                                その自立した強い姿は経営者と重なる部分があります。自立した経営者が集まり、より良い世界を実現し、喜びの涙を流したいという想いから、CRYINGTIGERは発足しました。<br />
                                <br />
                                「稼ぐ」とは、人を幸せにすること。<br />
                                「遊ぶ」とは、楽しむ心を持つこと。<br />
                                「貢献する」とは、思いやりを持って生きること。<br />
                                これらをバランスよく行うことのできる「かっこいい」経営者を共に目指していきましょう。
                            </p>
                            <p className={delegate}>
                                代表 <span className={delegateName}>坂田 裕希</span>
                            </p>
                        </div>
                    </div>
                    <div className={backgroundDiv} style={{ right: right, left: left }} />
                </div>
            </div>
        </>
    );
});

export default TextImage02;
